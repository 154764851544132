
import { reactive } from 'vue';

// icons
import { mail, key, } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonCol, IonRow, IonButtons, IonButton, IonBackButton, IonIcon,
        IonCard, IonCardHeader, IonCardContent, IonItem, IonLabel, IonInput,
        loadingController, alertController, } from '@ionic/vue';
import LogoImg from '@/components/LogoImg.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { utils } from '@/composables/utils';

// services
import AuthService from '@/services/AuthService';

export default {
  name: 'RegisterPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonCol, IonRow, IonButtons, IonButton, IonBackButton, IonIcon,
                IonCard, IonCardHeader, IonCardContent, IonItem, IonLabel, IonInput, LogoImg, },
  setup() {
    const newUser = reactive({
      loginEmail: "",
      loginPsw: "",
      confirmLoginPsw: "",
    });

    // methods
    const store = useStore();
    const { t } = useI18n();

    const presentAlert = async (message: any, header: any = null) => {
      const obj: any = {
        header,
        message,
        buttons: ['OK']
      }
      const alert = await alertController.create(obj);
      return await alert.present();
    };

    const doRegister = async () => {
      const { loginEmail, loginPsw, confirmLoginPsw } = newUser;
      if (loginEmail && loginPsw && confirmLoginPsw) {
        if (loginPsw == confirmLoginPsw) {
          const loading = await loadingController.create({});
          await loading.present();
          try {
            const res = await AuthService.doRegister(loginEmail, loginPsw);
            store.dispatch('createNewUser', { uid: res.user?.uid, ...newUser, });
          } catch (e) {
            presentAlert(e.message, t('errorHeader')); // wrong email or wrong password
          }
          loading.dismiss();
        } else {
          // password not match
          presentAlert( t('inconsistentPasswordMsg'), t('errorHeader') );
        }
      } else {
        presentAlert( t('RegisterPage.enterEmailAndPassword'), t('errorHeader') );
      }
    };
    
    return {
      t,

      // icons
      mail, key,

      // variables
      newUser,

      // methods
      doRegister,
    }
  },
}
