import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withKeys as _withKeys, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_logo_img = _resolveComponent("logo-img")!
  const _component_ion_card_header = _resolveComponent("ion-card-header")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_card_content = _resolveComponent("ion-card-content")!
  const _component_ion_card = _resolveComponent("ion-card")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.t('RegisterPage.registerAccount')), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button, { "default-href": "/login" })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_grid, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_row, { class: "ion-justify-content-center" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_col, {
                    size: "12",
                    "size-md": "8",
                    "size-lg": "6",
                    "size-xl": "4"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_card, { class: "ion-text-center" }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_card_header, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_logo_img, { withText: true })
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_card_content, null, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('RegisterPage.loginEmail')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    inputmode: "email",
                                    type: "text",
                                    modelValue: $setup.newUser.loginEmail,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.newUser.loginEmail) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('RegisterPage.password')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    type: "password",
                                    modelValue: $setup.newUser.loginPsw,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.newUser.loginPsw) = $event))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_item, null, {
                                default: _withCtx(() => [
                                  _createVNode(_component_ion_label, { position: "floating" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString($setup.t('RegisterPage.confirmPassword')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_ion_input, {
                                    type: "password",
                                    modelValue: $setup.newUser.confirmLoginPsw,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.newUser.confirmLoginPsw) = $event)),
                                    onKeyup: _cache[3] || (_cache[3] = _withKeys(($event: any) => ($setup.doRegister()), ["enter"]))
                                  }, null, 8, ["modelValue"])
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_ion_button, {
                                class: "submit-btn",
                                expand: "block",
                                onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.doRegister(_ctx.loginEmail, _ctx.loginPsw, _ctx.confirmLoginPsw)))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString($setup.t('RegisterPage.register')), 1)
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}